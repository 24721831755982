<template>
  <div>
    <div>
      <div class="content" style="text-align:unset;">
        <div>
          <div>
            <div>
                <div class="table-border" v-html="content">
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="suspend" @click="openDialog">
      <div class="item">
        <img src="@/img/index/customer-service.png" />
        <div>智能客服</div>
      </div>
    </div>
    <siteFooter></siteFooter>
    <el-dialog style="padding: 0" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="1200px">
      <custService></custService>
    </el-dialog>
  </div>
</template>

<script>
import request from '@/api/request';
import custService from "@/view/custService/custService";
import siteFooter from "@/components/siteFooter";

export default {
  components: {
    'custService': custService,
    siteFooter: siteFooter,
  },
  data() {
    return {
      dialogTableVisible: false,
      content: '',
    };
  },
  created() {
    this.actuarialList(this.$route.params.id)
  },
  methods: {
    openDialog() {
      this.dialogTableVisible = true;
    },
    async actuarialList(routerId) {
      request.post("/api/Project/GetPublicDetail",
        {
          id: this.$route.params.id,
        }
      )
        .then((result) => {
          // console.log(result)
          this.content = result.data.Data.content;
        })
    },
  },
};
</script>
<style scoped>
.table-border>>> table{
  margin: auto;
}
/* .table-border>>> table tr{
  padding-left: 5px;
  border:1px solid #dddddd;
}
.table-border>>> table td{
  padding-left: 5px;
  border:1px solid #dddddd;
} */
</style>
<style>
.content {
  background-color: #ffffff;
  width: 700px;
  margin: 20px auto;
  padding: 30px 20px;
  z-index: 99;
  text-align: center;
  overflow:hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}
.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}

</style>