import { render, staticRenderFns } from "./policyIndex.vue?vue&type=template&id=436b4d38&scoped=true"
import script from "./policyIndex.vue?vue&type=script&lang=js"
export * from "./policyIndex.vue?vue&type=script&lang=js"
import style0 from "./policyIndex.vue?vue&type=style&index=0&id=436b4d38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436b4d38",
  null
  
)

export default component.exports