<template>
  <div>
    <div style="height: 550px;">
      <el-table :data="activities" stripe max-height="400" style="width: 90%;height: 550px;margin: 0px auto;">
        <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column prop="title" label="活动名称"></el-table-column>
        <el-table-column prop="time" label="活动时间" width="160px">
        </el-table-column>
        <el-table-column prop="name" label="联系人"> </el-table-column>
        <el-table-column prop="mobile" label="联系电话"> </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button link type="primary" size="mini" @click="evaluate(scope.row.id, scope.row.end_time)"
              v-if="scope.row.status == 0">
              我要评价
            </el-button>
            <el-button link type="primary" size="mini" disabled v-else>
              已评价
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="
                width: 90%;
                margin: 20px auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: left;
              ">
      <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :pageSize="pageSize"
        layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
    <el-dialog title="评价" :visible.sync="dialogVisible" width="30%" @close="handleClose">
      <el-rate v-model="rating" :max="5" show-score text-template="{value}分" style="margin-bottom: 20px"></el-rate>
      <el-input v-model="comment" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" placeholder="请输入您的评价"
        maxlength="100" show-word-limit></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRating">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/api/request";
export default {
  name: "myActivity",
  data() {
    return {
      dialogVisible: false,
      rating: 0,
      comment: "",
      activities: [],
      pageSize: 5,
      currentPage: 1,
      total: null,
      id: null,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      request
        .post("/api/Activity/GetUserList", {
          pagesize: this.pageSize,
          pageindex: this.currentPage,
        })
        .then((response) => {
          // console.log(response);
          // 请求成功时处理数据
          if (response.data.Data && response.data.Data.data) {
            this.activities = [...response.data.Data.data];
            this.total = response.data.Data.total;
            this.formateList();
            // console.log(this.activities);
          }
        })
        .catch((error) => {
          // 请求失败时的处理
          console.error("Error fetching data:", error);
        });
    },
    formateList() {
      var index = 0;
      this.activities.forEach((activity) => {
        activity["time"] =
          activity.begin_time.replace(/[a-zA-Z]/g, " ") +
          "~" +
          activity.end_time.replace(/[a-zA-Z]/g, " ");
      });
    },

    evaluate(id, endTime) {
      if (Date.now() > parseInt(new Date(endTime).getTime())) {
        this.id = id;
        this.dialogVisible = true;
      } else {
        this.$message.error("活动结束后即可评价");
        return;
      }
    },
    handleClose() {
      // 关闭对话框时重置评分和评论
      this.rating = 0;
      this.comment = "";
    },
    submitRating() {
      // console.log(this.rating, this.comment);
      this.$confirm("确认评价？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          request.post("/api/Activity/Evaluate", {
            id: this.id,
            evaluation: this.rating,
            content: this.comment,
          })
            .then((res) => {
              // console.log(res);
              if (res.data.Status == 1) {
                this.$message({
                  type: "success",
                  message: "评价成功!",
                });
                this.dialogVisible = false; // 关闭对话框
              } else {
                this.$message({
                  type: "error",
                  message: "评价失败!",
                });
                this.dialogVisible = false; // 关闭对话框
              }
              this.rating = 0; // 重置评分
              this.comment = ""; // 重置评价
            });
        })
        .catch(() => {
          this.rating = 0; // 重置评分
          this.comment = ""; // 重置评价
        });
    },
    // 当当前页发生变化时的回调
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initData(); // 重新查询数据
    },
  },
};
</script>