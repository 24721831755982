<template>
  <div>
    <demandsHeader></demandsHeader>
    <pageBanner></pageBanner>
    <div class="content">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item
          label="诉求标题"
          label-width="155px"
          class="formitem"
          align-items="center"
          prop="title"
        >
          <el-input class="el-select_input" v-model="form.title" placeholder="请输入诉求标题"></el-input>
        </el-form-item>
        <el-form-item
          label="诉求类型"
          label-width="155px"
          class="formitem"
          align-items="center"
          prop="type"
        >
          <el-select v-model="form.type" placeholder="请选择" style="width: 100%;">
            <el-option
              v-for="item in typeoptions"
              :label="item"
              :value="item"
              style="padding-left: 10px;"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="诉求内容"
          label-width="155px"
          class="formitem"
          align-items="center"
          prop="content"
        >
          <el-input
            type="textarea"
            class="el-select_input"
            v-model="form.content"
            style="background-color: #F5F5F5;"
            :rows="5"
            placeholder="请输入诉求内容"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="诉求上报人"
          label-width="155px"
          class="formitem"
          align-items="center"
          prop="name"
        >
          <el-input class="el-select_input" placeholder="请输入诉求上报人" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="上报人联系手机"
          label-width="155px"
          class="formitem"
          align-items="center"
          prop="mobile"
        >
          <el-input class="el-select_input" placeholder="请输入上报人联系手机" v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" label-width="155px" prop="Code" class="formitem">
          <el-input
            class="el-select_input"
            style="width: 100%; margin-left: 0px;"
            placeholder="短信验证码"
            v-model="form.Code"
          ></el-input>
          <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
            <span v-show="show">获取验证码</span>
            <span v-show="!show" class="count">{{ count }} s</span>
          </button>
        </el-form-item>
        <el-form-item label="相关附件" label-width="155px" prop="Code" class="formitem">
          <div>
            <el-upload
              v-loading="loading"
              element-loading-text="努力上传中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="upload-demo"
              drag
              :http-request="uploadFile"
              action
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
              multiple
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </el-upload>
          </div>
        </el-form-item>
        <el-button
          type="primary"
          style="font-size: 16px; padding: 15px 80px;"
          :disabled="isdisabled"
          @click="SubmitAClaim"
        >提交诉求</el-button>
      </el-form>
    </div>
    <div class="suspend" @click="openDialog">
      <div class="item">
        <img src="@/img/index/customer-service.png" />
        <div>智能客服</div>
      </div>
    </div>
    <siteFooter></siteFooter>
    <el-dialog style="padding: 0" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="1200px">
      <custService></custService>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/api/request";
import demandsHeader from "@/components/demandsHeader";
import siteFooter from "@/components/siteFooter";
import custService from "@/view/custService/custService";
import pageBanner from "@/view/demands/components/pageBanner";
export default {
  name: "demandsAdd",
  components: {
    demandsHeader: demandsHeader,
    'custService': custService,
    pageBanner: pageBanner,
    siteFooter: siteFooter
  },
  data() {
    return {
      form: {
        // demandEnterprise: '',
        // sclaimtitleubdistricttown: '',
        title: "",
        type: "",
        content: "",
        name: "",
        mobile: "",
        Code: ""
      },
      dialogTableVisible: false,
      count: 0,
      show: true,
      isdisabled: false,
      typeoptions: [],
      fileList: [],
      loading: false,
      rules: {
        title: [
          {
            required: true,
            message: "请输入诉求标题",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "请选择诉求类型",
            trigger: "change"
          }
        ],
        content: [
          {
            required: true,
            message: "请输入诉求内容",
            trigger: "blur"
          }
        ],
        name: [
          {
            required: true,
            message: "请输入诉求上报人",
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            message: "请输入上报人联系手机",
            trigger: "blur"
          },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        Code: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.getDemandsType();
    if (!localStorage.getItem("USERID")) {
      this.$message({
        message: "请先登录系统",
        type: "warning"
      });
      this.$router.push("/login");
    } else if (localStorage.getItem("QYID") == 0) {
      this.$confirm("请先关联企业", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success"
      })
        .then(() => {
          this.$router.push("/personalcenter/0");
        })
        .catch(() => {
          this.$router.push("/");
        });
    } else if (localStorage.getItem("demandstimecount")) {
      this.count = localStorage.getItem("demandstimecount");
      this.show = false;
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= 60) {
          this.count--;
          localStorage.setItem("demandstimecount", this.count);
        } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          localStorage.removeItem("demandstimecount");
        }
      }, 1000);
    }
  },
  methods: {
    openDialog() {
      this.dialogTableVisible = true;
    },
    handlePreview(file) {
      window.open(file.path, "_blank");
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      // console.log(fileList);
    },
    uploadFile(item) {
      let base64String = "";
      let namefile = "";
      this.loading = true;
      if (window.FileReader) {
        namefile = item.file.name;
        let reader = new FileReader();
        reader.readAsDataURL(item.file);
        reader.onload = e => {
          base64String = e.target.result;
          let url = "/api/Project/UploadByBase64";
          let reqObj = {
            filename: namefile,
            image: base64String
          };
          // console.log(reqObj)
          request.post(url, reqObj).then(res => {
            // console.log(res);
            let iconurl = "";
            if (res.data.Data.Ext == "doc" || res.data.Data.Ext == "docx") {
              iconurl =
                "https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081430144073.png";
            } else if (
              res.data.Data.Ext == "xls" ||
              res.data.Data.Ext == "xlsx"
            ) {
              iconurl =
                "https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081430335953.png";
            } else if (res.data.Data.Ext == "pdf") {
              iconurl =
                "https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081429127427.png";
            } else {
              iconurl =
                "https://xinyenetwork.oss-cn-hangzhou.aliyuncs.com/upload/202406/08/202406081428492318.png";
            }
            //this.fileList = [...res.data.Data.map(item => { return { name: item.Name, path: item.Path, ext: item.Ext, size: item.Size, url: iconurl } })]
            this.fileList.push({
              name: res.data.Data.Name,
              path: res.data.Data.Path,
              ext: res.data.Data.Ext,
              size: res.data.Data.Size,
              url: iconurl
            });
            this.loading = false;
          });
          // console.log(this.fileList)
        };
      }
      // const formData = new FormData()
      // formData.append('processDefinition', item.file) // 传入bpmn文件
      // console.log(formData);
    },
    getDemandsType() {
      let url = "/api/Demands/getType";
      request.get(url).then(res => {
        // console.log(res);
        if (res.data.Status == 1) {
          this.typeoptions = res.data.Data;
        } else {
          return this.$confirm("诉求类型获取失败，请重新获取");
        }
      });
    },
    SubmitAClaim() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.isdisabled = true;
          let imagelist = [];
          if (this.fileList.length > 0) {
            imagelist = this.fileList.map(item => {
              return {
                file_name: item.name,
                file_path: item.path,
                file_size: item.size,
                file_ext: item.ext
              };
            });
          }
          let url = "/api/Demands/Apply";
          let reqObj = {
            name: this.form.name,
            type: this.form.type,
            mobile: this.form.mobile,
            title: this.form.title,
            content: this.form.content,
            Code: this.form.Code,
            list: imagelist
          };
          request.post(url, reqObj).then(res => {
            // console.log(res);
            this.isdisabled = false;
            if (res.data.Status == 1) {
              this.$confirm("提交成功", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success"
              })
                .then(() => {
                  this.$router.push("/personalcenter/5");
                })
                .catch(() => {
                  location.reload();
                });
            } else {
              return this.$confirm("提交失败，请重新提交");
            }
          });
        } else {
          return this.$confirm("请完善信息后提交诉求");
        }
      });
    },
    // 获取短信验证码
    getCode() {
      //axios请求
      // console.log(this.form.mobile);
      // 验证码倒计时
      if (
        !this.form.mobile ||
        !/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.form.mobile)
      ) {
        this.$message({
          message: "手机号无效",
          type: "warning"
        });
      } else if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
            localStorage.setItem("demandstimecount", this.count);
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            localStorage.removeItem("demandstimecount", this.count);
          }
        }, 1000);
        this.getCodeNumber(this.form.mobile);
      }
    },
    getCodeNumber(phone) {
      request
        .post("/api/Sms/Send", {
          keyword: phone
        })
        .then(res => {
          // console.log(res)
        })
        .catch(error => {
          // 请求失败，处理错误
          console.error("Error fetching data:", error);
        });
    }
  }
};
</script>
<style scoped>
@import url("../../../public/layui/css/layui.css");

* {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.content {
  background-color: #ffffff;
  width: 700px;
  margin: 20px auto;
  padding: 30px 20px;
  z-index: 99;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.formitem {
  margin: 20px 0px;
  font-size: 17px;
  color: #606266;
  text-align: left;
  justify-content: space-between;
}

/deep/ .el-input__inner {
  background-color: #f5f5f5;
}

/deep/ .el-textarea__inner {
  background-color: #f5f5f5;
}

.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}
.el-upload {
  width: 100px;
  display: none !important;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.el-upload-list__item:first-child {
  margin-top: 0.625rem;
}

.el-upload-list__item {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  font-size: 0.875rem;
  color: #606266;
  line-height: 1.8;
  margin-top: 0.3125rem;
  position: relative;
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: 100%;
}

.el-upload-list__item-name {
  color: #606266;
  display: block;
  margin-right: 2.5rem;
  overflow: hidden;
  padding-left: 0.25rem;
  text-overflow: ellipsis;
  transition: color 0.3s;
  white-space: nowrap;
}

.el-upload-list__item-status-label {
  position: absolute;
  right: 0.3125rem;
  top: 0;
  line-height: inherit;
  display: none;
}

.el-upload-list__item-name [class^="el-icon"] {
  height: 100%;
  margin-right: 0.4375rem;
  color: #909399;
  line-height: inherit;
}

.upload-demo {
  width: 100%;
  text-align: left;
}
</style>

<style scoped>
/deep/.el-upload-list--picture .el-upload-list__item {
  overflow: hidden;
  z-index: 0;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  margin-top: 10px;
  width: 45% !important;
  padding: 10px 10px 10px 90px;
  height: 92px;
  margin: 5px 5px 10px 0px !important;
  display: inline-block;
}
.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}
</style>