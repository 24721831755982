<template>
    <div>
        <div class="qw-footer" style="margin-top: auto; width:100%;text-align:center;">
            <div class="qw-footer__inner col-center el-row el-row--flex">
                <div class="lf flex el-col el-col-24">
                    <div style="margin: 0px auto;display: flex;justify-content: center;">
                        <div class="flex-col">
                            <span class="flex-1">建设单位：启东市企业服务中心</span>
                            <span class="flex-1">地址：金桥路199号政务服务中心三楼</span>
                            <span class="flex-1">服务热线：0513-83111717</span>
                        </div>
                        <div class="flex-col">
                            <span class="flex-1">版权所有：Copyright©启东市企业服务中心</span>
                            <span class="flex-1">技术支持：江苏南通鑫业网络科技有限公司（400-003-0513）</span>
                            <span class="flex-1"><a href="https://beian.miit.gov.cn/"
                                    target="_blank">备案号：苏ICP备2023005773号-2</a></span>
                        </div>
                        <div class="flex-col">
                            <span class="flex-1" style="cursor: pointer;" @click="aboutus">关于我们</span>
                        </div>
                        <div>
                            <span style="cursor: pointer;" @click="aboutus"><img style="width:90px;height:90px;padding-top:18px;" src="@/images/about_img.png" alt="启东企业服务E企通公众号二维码" srcset="">
                                
                            </span>
                        </div>
                    </div>
                    <div>
                        <!-- <div class="about" @click="Huiqitong">惠企通</div>
                        <div class="about" @click="thousands">千人千企</div> -->
                        <!-- <div class="about"></div> -->
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        Huiqitong() {
            window.open('https://hqt.nantong.gov.cn/', '_blank')
        },
        thousands() {
            window.open('https://qdqrqq.com/thousands/login.aspx', '_blank')
        },
        aboutus() {
            let routeData = this.$router.resolve('/about')
            window.open(routeData.href, '_blank')

        }
    }
}
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
}

.qw-footer {
    z-index: 99999;
    background: #515151;
    color: #fff;
}

.qw-footer__inner {
    margin: 0 auto;
    width: 64.375rem;
}

.qw-footer__inner::after {
    display: block;
    clear: both;
    content: '';
}

.qw-footer__inner .lf {
    height: 100%;
    display: flex;
    font-size: 0.875rem;
    position: relative;
    flex: 2.2;
    margin: 0px auto;
    text-align: center;
    /* justify-content: space-between; */
    align-items: baseline;
}

.qw-footer__inner .lf .flex-col {
    width: 33.33333%;
    padding: 1.125rem 0;
    margin: 0px auto;
}

.qw-footer__inner .lf .flex-col:nth-child(1) {
    flex: 1.1;
}

.qw-footer__inner .lf .flex-col:nth-child(2) {
    flex: 1.2;
}

.qw-footer__inner .lf .flex-col:nth-child(3) {
    flex: 0.4;
}

.qw-footer__inner .lf a {
    color: #fff;
}

.qw-footer__inner .lf .flex-1 {
    width: 100%;
    padding: 0.3125rem;
    margin:  0px 52px;
    display: block;
    text-align: left;
}

.qw-footer__inner .link-box {
    position: absolute;
    background: #717171;
    top: -14rem;
    display: flex;
    flex-direction: column;
    right: 0;
    padding: 1.25rem;
    z-index: 1;
    visibility: visible;
}

.qw-footer__inner .link-box a {
    margin-bottom: 0.5rem;
}

.qw-footer__inner .link-box a:last-child {
    margin-bottom: 0;
}

.qw-footer__inner .link-box.hidden {
    visibility: hidden;
}

.qw-footer__inner .rt {
    padding: 0.75rem 0;
    display: flex;
    justify-content: end;
    flex: 0.6;
    font-size: 0.875rem;
    align-items: center;
}

.about {
    margin: 10px 43px;
    font-size: 15px;
}
</style>