import { render, staticRenderFns } from "./policyInterpretationDeteils.vue?vue&type=template&id=57b8b59f&scoped=true"
import script from "./policyInterpretationDeteils.vue?vue&type=script&lang=js"
export * from "./policyInterpretationDeteils.vue?vue&type=script&lang=js"
import style0 from "./policyInterpretationDeteils.vue?vue&type=style&index=0&id=57b8b59f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57b8b59f",
  null
  
)

export default component.exports