<template>
  <div>
    <demandsHeader></demandsHeader>
    <div>
      <pageBanner></pageBanner>
      <div class="content">
        <div style="width: 400px;margin: 20px auto;padding: 52px 0px;">
          <img src="../../images/about-logo.png" style="width: 100%;" />
        </div>
        <div class="intro">
          <p
            class="intro-desc"
          >启东市企业服务中心是市发展改革委员会下属事业单位，成立于2024年6月，位于市行政服务中心三楼西北侧。中心秉持“无事不扰、有求必应”的品牌理念，以“只进一扇门、只上一张网、办成所有事”为目标，围绕“企业服务”主题，通过创新服务机制、整合各方资源，搭建了汇集政策匹配、供需对接、诉求流转、宣传展示等功能的服务“总入口”，为企业提供全规模、全周期的集成式服务，成为助企惠企的“温馨家园”。</p>
        </div>
      </div>
    </div>
    <div>
      <div class="suspend" @click="openDialog">
        <div class="item">
          <img src="@/img/index/customer-service.png" />
          <div>智能客服</div>
        </div>
      </div>
      <siteFooter></siteFooter>
      <el-dialog
        style="padding: 0"
        :visible.sync="dialogTableVisible"
        :modal-append-to-body="false"
        width="1200px"
      >
        <custService></custService>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import demandsHeader from "@/components/demandsHeader";
import custService from "@/view/custService/custService";
import pageBanner from "@/view/about/components/pageBanner";
import siteFooter from "@/components/siteFooter";
export default {
  name: "demandsAdd",
  components: {
    demandsHeader: demandsHeader,
    custService: custService,
    pageBanner: pageBanner,
    siteFooter: siteFooter
  },
  data() {
    return {
      dialogTableVisible: false
    };
  },
  methods: {
    openDialog() {
      this.dialogTableVisible = true;
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  width: 950px;
  margin: 20px auto;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.intro {
  padding: 0px 30px 52px 30px;
}

.intro-title {
  font-weight: bold;
  text-align: center;
  font-size: 1.325rem;
  margin: 20px auto;
}

.intro-desc {
  line-height: 1.875rem;
  text-indent: 2em;
  text-align: left;
  font-size: 16px;
}
.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}
</style>