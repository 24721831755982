<template>
  <div>
    <div v-if="visible" class="overlay"></div>
    <demands-header></demands-header>
    <activity-page-banner></activity-page-banner>
    <div class="activity-details">
      <!-- 上半部分 -->
      <div class="header">
        <div class="image">
          <img v-if="activity.imgUrl" :src="activity.imgUrl" alt="活动图片" />
          <img v-else src="../../images/about-logo.png" />
        </div>

        <div class="details" style="margin-left: 20px">
          <div style="margin-bottom: 10px">
            <h3 style="height: 20">{{ activity.title }}</h3>
          </div>
          <p style="color: #6d6d6d">
            活动时间: {{ activity.begin_time }} 至 {{ activity.end_time }}
          </p>
          <p style="color: #6d6d6d">活动地点: {{ activity.address }}</p>
          <p style="color: #6d6d6d">主办单位: {{ activity.organizers }}</p>
          <p style="color: #6d6d6d">
            报名时间: {{ activity.activitybegin_time }} 至
            {{ activity.activityend_time }}
          </p>
          <!-- <p style="color: #6d6d6d">浏览量: {{ activity.click }}</p> -->
          <div v-if="isEnd">
            <span style="font-size: 16px; color: #6d6d6d">综合评分：</span>
            <div style="display: inline-block">
              <el-rate
                v-model="activity.activityevaluation"
                disabled
                show-score
                text-color="#ff9900"
              >
              </el-rate>
            </div>
          </div>
          <div v-if="currentStatus === 1 && isLogin">
            <el-button
              style="display: inline-block; margin: 20px"
              type="primary"
              @click="dialogFormVisible = true"
              :disabled="confirmSignUp"
              >我要报名</el-button
            >
            <el-button
              style="display: inline-block; margin: 20px"
              type="danger"
              :disabled="cancelSignUp"
              @click="cancelDialog"
              >取消报名</el-button
            >
          </div>
          <el-dialog
            title="活动报名"
            width="40%"
            :visible.sync="dialogFormVisible"
          >
            <el-form :model="form">
              <el-form-item label="公司名称" :label-width="formLabelWidth">
                <el-input
                  v-model="form.companyName"
                  autocomplete="off"
                  placeholder="请输入公司名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="参与人数" :label-width="formLabelWidth">
                <el-input-number
                  v-model="form.memberNum"
                  :min="1"
                  :max="100"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="联系人" :label-width="formLabelWidth">
                <el-input
                  v-model="form.contactPerson"
                  placeholder="请输入联系人"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" :label-width="formLabelWidth">
                <el-input
                  v-model="form.phone"
                  placeholder="请输入联系电话"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleConfirm">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
      <div class="collect">
        <div class="collect-content" @click="Collect">
          <span v-if="iscollection == 0"
            ><i class="el-icon-star-off">收藏</i></span
          >
          <span v-if="iscollection == 1"
            ><i class="el-icon-star-on" style="font-weight: bold"
              >已收藏</i
            ></span
          >
          <!-- <span ><i class="el-icon-star-off">收藏</i></span> -->
        </div>
        <div class="collect-content" @click="Share">
          <i class="el-icon-share">分享</i>
        </div>
      </div>
      <!-- 下半部分 -->
      <div class="content">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="活动内容" name="content">
            <div
              v-if="activeTab === 'content'"
              class="tab-content"
              v-html="activity.content"
            ></div>
          </el-tab-pane>
          <el-tab-pane label="活动回顾" name="review">
            <div
              v-if="activeTab === 'review'"
              class="tab-content"
              v-html="activity.summary"
            ></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="sharecontent" v-if="sharecontent">
      <div class="sharebg">
        <div class="sharebgcontent">
          <div
            style="
              width: 95%;
              max-height: 300px;
              margin: 0px auto;
              overflow: hidden;
              overflow-y: auto;
            "
          >
            <div class="activity-details">
              <!-- 上半部分 -->
              <div class="header">
                <div class="image" style="width: 100px">
                  <img
                    v-if="activity.imgUrl"
                    :src="activity.imgUrl"
                    alt="活动图片"
                  />
                  <img v-else src="../../images/about-logo.png" />
                </div>
                <div class="details">
                  <div style="margin-bottom: 10px">
                    <h3 style="height: 20">{{ activity.title }}</h3>
                  </div>
                  <p style="color: #6d6d6d">
                    活动时间: {{ activity.begin_time }} 至
                    {{ activity.end_time }}
                  </p>
                  <p style="color: #6d6d6d">活动地点: {{ activity.address }}</p>
                  <p style="color: #6d6d6d">
                    主办单位: {{ activity.organizers }}
                  </p>
                  <p style="color: #6d6d6d">
                    报名时间: {{ activity.activitybegin_time }} 至
                    {{ activity.activityend_time }}
                  </p>
                  <div v-if="currentStatus === 1 && isLogin">
                    <el-button
                      style="display: inline-block; margin: 20px"
                      type="primary"
                      @click="dialogFormVisible = true"
                      :disabled="confirmSignUp"
                      >我要报名</el-button
                    >
                    <el-button
                      style="display: inline-block; margin: 20px"
                      type="danger"
                      :disabled="cancelSignUp"
                      @click="cancelDialog"
                      >取消报名</el-button
                    >
                  </div>
                  <el-dialog
                    title="活动报名"
                    width="40%"
                    :visible.sync="dialogFormVisible"
                  >
                    <el-form :model="form">
                      <el-form-item
                        label="公司名称"
                        :label-width="formLabelWidth"
                      >
                        <el-input
                          v-model="form.companyName"
                          autocomplete="off"
                          placeholder="请输入公司名称"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="参与人数"
                        :label-width="formLabelWidth"
                      >
                        <el-input-number
                          v-model="form.memberNum"
                          :min="1"
                          :max="100"
                        ></el-input-number>
                      </el-form-item>
                      <el-form-item
                        label="联系人"
                        :label-width="formLabelWidth"
                      >
                        <el-input
                          v-model="form.contactPerson"
                          placeholder="请输入联系人"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="联系电话"
                        :label-width="formLabelWidth"
                      >
                        <el-input
                          v-model="form.phone"
                          placeholder="请输入联系电话"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="dialogFormVisible = false"
                        >取 消</el-button
                      >
                      <el-button type="primary" @click="handleConfirm"
                        >确 定</el-button
                      >
                    </div>
                  </el-dialog>
                </div>
              </div>
              <!-- 下半部分 -->
              <div class="content">
                <el-tabs v-model="activeTab">
                  <el-tab-pane label="活动内容" name="content">
                    <div
                      v-if="activeTab === 'content'"
                      class="tab-content"
                      v-html="activity.content"
                    ></div>
                  </el-tab-pane>
                  <el-tab-pane label="活动回顾" name="review">
                    <div
                      v-if="activeTab === 'review'"
                      class="tab-content"
                      v-html="activity.summary"
                    ></div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 30px">
          <img
            :src="shareqrcodeimg"
            alt=""
            srcset=""
            style="width: 200px; height: 200px"
          />
        </div>
      </div>
      <button v-if="shareqrcodeimg" @click="downloadshareqrcodeimg">
        下载图片
      </button>
      <div style="font-size: 29px; color: #ffffff" @click="sharecontentclose">
        <i class="el-icon-circle-close"></i>
      </div>
    </div>
    <div class="suspend" @click="openDialog">
      <div class="item">
        <img src="@/img/index/customer-service.png" />
        <div>智能客服</div>
      </div>
    </div>
    <site-footer></site-footer>
    <el-dialog style="padding: 0" :visible.sync="dialogTableVisible2" :modal-append-to-body="false" width="1200px">
      <custService></custService>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/api/request";
import demandsHeader from "@/components/demandsHeader";
import siteFooter from "@/components/siteFooter";
import custService from "@/view/custService/custService";
import activityPageBanner from "@/components/activityPageBanner";
export default {
  components: {
    demandsHeader: demandsHeader,
    'custService': custService,
    siteFooter: siteFooter,
    activityPageBanner: activityPageBanner,
  },
  data() {
    return {
      activeTab: "content", // 默认选中活动内容
      activity: [],
      currentStatus: 0,
      shareqrcodeimg: "",
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogTableVisible2: false,
      form: {
        companyName: "",
        memberNum: 1,
        contactPerson: "",
        phone: "",
      },
      formLabelWidth: "120px",
      confirmSignUp: false,
      cancelSignUp: true,
      id: null,
      isLogin: false,
      isEnd: false, // 活动是否已结束
      iscollection: 0,
      visible: false,
      sharecontent: false,
    };
  },
  created() {
    this.initData();
    this.getSignUpInfo();
  },
  methods: {
    openDialog() {
      this.dialogTableVisible2 = true;
    },
    downloadshareqrcodeimg() {
      this.downloadIamge(this.shareqrcodeimg, "活动分享");
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    sharecontentclose() {
      this.visible = false;
      this.sharecontent = false;
    },
    Share() {
      request
        .post("/api/Mp/GetQrcode", {
          id: this.$route.query.id,
          type: "activityinfo",
        })
        .then(
          (response) => {
            // console.log(response)
            if (response.data.Status == 1) {
              this.shareqrcodeimg = response.data.Data;
              this.visible = true;
              this.sharecontent = true;
            } else {
              this.$message({
                message: "分享失败",
                type: "error",
              });
            }
          },
          (error) => {
            // console.log('错误', error.message)
          }
        );
    },
    Collect() {
      if (!localStorage.getItem("USERID")) {
        this.$message({
          message: "还未登录，请先登录",
          type: "error",
          duration: 2000,
        });
        setTimeout(() => {
          this.$router.push("/login");
        }, 2000);
      } else if (this.iscollection == 0) {
        request
          .post("/api/Policy/Gocollection", {
            typeid: 2,
            specificid: this.$route.query.id,
          })
          .then(
            (response) => {
              // console.log(response)
              this.initData();
              this.$message({
                message: response.data.Msg,
                type: "success",
              });
            },
            (error) => {
              // console.log('错误', error.message)
            }
          );
      } else if (this.iscollection == 1) {
        request
          .post("/api/Policy/Deletecollection", {
            typeid: 2,
            specificid: this.$route.query.id,
          })
          .then(
            (response) => {
              // console.log(response)
              this.initData();
              this.$message({
                message: response.data.Msg,
                type: "success",
              });
            },
            (error) => {
              // console.log('错误', error.message)
            }
          );
      }
    },
    initData() {
      this.id = this.$route.query.id;
      request
        .post("/api/Activity/GetActivityDetails", { id: this.id })
        .then((response) => {
          // console.log(response);
          this.activity = response.data.Data[0];
          var iscollection = 0;
          if (this.activity.collection == "登录未收藏") {
            iscollection = 0;
          } else if (this.activity.collection == "已收藏") {
            iscollection = 1;
          }
          this.activity.begin_time = this.activity.begin_time.replace(
            /[a-zA-Z]/g,
            " "
          );
          this.activity.end_time = this.activity.end_time.replace(
            /[a-zA-Z]/g,
            " "
          );

          // console.log(Date.now(), Date.parse(this.activity.activityend_time));
          if (
            Date.now() >= Date.parse(this.activity.activitybegin_time) &&
            Date.now() <= Date.parse(this.activity.activityend_time)
          ) {
            this.currentStatus = 1;
          }

          this.activity.activitybegin_time =
            this.activity.activitybegin_time.replace(/[a-zA-Z]/g, " ");
          this.activity.activityend_time =
            this.activity.activityend_time.replace(/[a-zA-Z]/g, " ");
          this.iscollection = iscollection;
          // console.log(this.iscollection)

          if (response.data.Data[0].user_name) {
            this.isLogin = true;
          }

          if (Date.now() > Date.parse(this.activity.end_time)) {
            this.isEnd = true;
          }
        })
        .catch((error) => {});
    },

    // 获取当前用户是否已经报名该活动，已经报名则可以取消，未报名则可以报名
    getSignUpInfo() {
      // console.log(this.id);
      request
        .post("/api/Activity/GetActivity", { id: this.id })
        .then((response) => {
          // console.log(response);
          this.confirmSignUp = response.data.Status !== 1;
          this.cancelSignUp = response.data.Status !== 0;
        })
        .catch((error) => {
          // console.log(error)
        });
    },

    signUp() {
      this.id = this.$route.query.id;
      request
        .post("/api/Activity/Apply", {
          activityid: this.id,
          ccount: this.form.memberNum,
          name: this.form.contactPerson,
          mobile: this.form.phone,
          qy: this.form.companyName,
        })
        .then((response) => {
          this.getSignUpInfo();
        })
        .catch((error) => {});
    },

    callOffSignUp() {
      this.id = this.$route.query.id;
      request
        .post("/api/Activity/deleteActivity", { id: this.id })
        .then((response) => {
          this.getSignUpInfo();
        })
        .catch((error) => {});
    },

    handleConfirm() {
      if (!this.form.companyName) {
        this.$message.error("请填写公司名称");
        return;
      }
      if (!this.form.contactPerson) {
        this.$message.error("请填写联系人");
        return;
      }
      if (!this.form.phone) {
        this.$message.error("请填写联系电话");
        return;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.form.phone)) {
        // 如果手机号格式不正确，显示错误提示
        this.$message.error("手机号格式不正确，请重新输入");
        return;
      }
      this.dialogFormVisible = false;
      this.open();
    },

    open() {
      this.$confirm("确认报名？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          // console.log("处理报名逻辑");
          this.signUp();
          this.$message({
            type: "success",
            message: "报名成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    cancelDialog() {
      this.$confirm("确认取消报名？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          // console.log("处理取消报名逻辑");
          this.callOffSignUp();
          this.$message({
            type: "success",
            message: "取消报名成功!",
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.activity-details {
  max-width: 800px;
  /* 控制活动详情页面最大宽度 */
  margin: 20px auto;
  /* 居中布局 */
  padding: 20px;
  background-color: white;
  /* 背景色 */
  border-radius: 10px;
  /* 整体圆角 */

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* 阴影效果 */
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.image img {
  width: 300px;
  height: auto;
  border-radius: 8px;
  /* 图片圆角 */
  margin-right: 30px;
}

.details {
  flex: 1;
}

.details h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.details p {
  font-size: 16px;
  margin-bottom: 5px;
}

.content {
  width: 90%;
  margin-top: 20px;
  text-align: left;
}

.tab-content {
  text-indent: 2rem;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.collect-content {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #028dfb;
  border-radius: 1.875rem;
  border: 1px solid #a9b8cd;
  border-color: #a9b8cd;
  color: #2664ca;
  padding: 10px 30px;
  margin: 10px 20px;
}

.collect {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust transparency as needed */
  z-index: 9999;
  /* Ensure it sits on top of other content */
}

.sharecontent {
  width: 38.4375rem;
  min-height: 580px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0px auto;
  text-align: center;
  z-index: 9999999999;
  /* Ensure it sits on top of other content */
}

.sharebg {
  width: 100%;
  min-height: 580px;
  background: url("@/images/poster-bg.png") no-repeat 100% 100%;
  background-size: 100% 100%;
  border-radius: 0.5625rem;
}

.sharecontent button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: red !important;
  color: #ffffff;
  border: none;
  margin: 20px auto;
}

.sharebgcontent {
  position: relative;
  top: 20px;
  width: 95%;
  border: 1rpx solid black;
  max-height: 380px;
  padding: 20px 10px;
  margin: 0px auto;
  border-radius: 20px !important;
  background-color: #ffffff;
}

.sharebgcontent >>> img {
  width: 100% !important;
}
.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}
</style>
