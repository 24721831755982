<template>
  <div>
    <demandsHeader></demandsHeader>
    <div class="content">
      <div class="content-item1">
        <div style="margin-top: 30px;cursor: pointer;">
          <ul>
            <li
              style="text-align: center;"
              :class="{ active: index == isactive }"
              v-for="(item, index) in systemNavigation"
              :key="index"
              @click="navigationSelect(item.url, index)"
            >
              <a v-if="item.name=='企业标签'"><span style="color:red;font-size:20px;">*</span>{{ item.name }}</a>
              <a v-if="item.name!='企业标签'">{{ item.name }}</a>
            </li>
            <!-- <li><a href="#one">关联企业</a></li>
                            <li><a href="">政策精算报告</a></li>
                            <li><a href="">工业产品</a></li>
                            <li><a href="#/personalcenter/fore">我的申报</a></li>
                            <li><a href="">我的收藏</a></li>
                            <li><a href="">我的活动</a></li>
                            <li><a href="">我的诉求</a></li>
                            <li><a href="">我的需求</a></li>
                            <li><a href="">我的供应</a></li>
                            <li><a href="">修改密码</a></li>
            <li><a href="">消息中心</a></li>-->
          </ul>
        </div>
        <div class="content-item" style="cursor: pointer;">
          <div id="ids0" style="font-size: 18px">
            <h1>当前企业:{{ Qy }}</h1>更换绑定企业:
            <el-select
              v-model="value"
              filterable
              @change="selectChanged"
              placeholder="请选择"
              style="width: 600px; height: 400px"
            >
              <el-option
                v-for="item in qyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                class="qy"
              ></el-option>
            </el-select>
            <el-button
              type="primary"
              round
              style="width: 200px"
              @click="updateqy"
              :disabled="EnterpriseDisabled"
            >保存</el-button>
          </div>
          <div id="ids1">
            <h1>{{ Qy || "请先关联企业" }}</h1>
            <el-transfer
              class="xuanxiang"
              style="width: 800px; height: 400px"
              v-model="oldList"
              :data="allList"
              :titles="title"
            ></el-transfer>
            <el-button
              type="primary"
              round
              style="width: 200px"
              @click="updatelable"
              v-if="Qy && tagflag"
            >保存</el-button>
          </div>
          <!-- <div id="ids2">政策精算报告</div>
          <div id="ids3">工业产品</div>-->
          <div id="ids2">
            <myApply></myApply>
          </div>
          <div id="ids3">
            <myCollect></myCollect>
          </div>
          <div id="ids4">
            <my-activity></my-activity>
          </div>
          <div id="ids5">
            <div v-if="demands_idshow">
              <div style="height: 550px;margin-top: -20px;">
                <el-table
                  :data="demandsList.tableData"
                  max-height="535"
                  style="width: 90%;height: 550px;margin-top: -10px; margin: 0px auto;"
                >
                  <el-table-column label="#" type="index" width="90" :index="indexMethod"></el-table-column>
                  <el-table-column label="诉求标题" width="200">
                    <template slot-scope="scope">
                      <span
                        style="width:200px;float:left;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                      >{{scope.row.name}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="date" label="诉求提交日期" width="200"></el-table-column>
                  <el-table-column prop="status" label="处理状态" width="120"></el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button
                        @click.native.prevent="detailsrow(scope.row)"
                        type="text"
                        size="small"
                      >详情</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                style="
                width: 90%;
                margin: 20px auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: left;
              "
              >
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :pageSize="pageSize"
                  :total="demandsList.total"
                  :current-page="indexval"
                  @current-change="handleCurrentChange"
                ></el-pagination>
              </div>
            </div>
            <div v-else>
              <span
                style="float: left;margin-left: 20px;padding: 10px 18px; background-color: #409EFF;color: #ffffff;cursor: pointer;"
                @click="demandsgo"
              >&lt;返回列表</span>
              <div>
                <DemandsDetails :id="demandsid"></DemandsDetails>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="suspend" @click="openDialog">
        <div class="item">
          <img src="@/img/index/customer-service.png" />
          <div>智能客服</div>
        </div>
      </div>
      <siteFooter></siteFooter>
      <el-dialog
        style="padding: 0"
        :visible.sync="dialogTableVisible"
        :modal-append-to-body="false"
        width="1200px"
      >
        <custService></custService>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import request from "@/api/request";
import demandsHeader from "@/components/demandsHeader";
import custService from "@/view/custService/custService";
import siteFooter from "@/components/siteFooter";
import myActivity from "./myActivity.vue";
import myCollect from "./myCollect.vue";
import DemandsDetails from "./DemandsDetails.vue";
import myApply from "./myApply.vue";
export default {
  name: "demandsAdd",
  components: {
    demandsHeader: demandsHeader,
    siteFooter: siteFooter,
    custService: custService,
    myActivity: myActivity,
    myApply: myApply,
    myCollect: myCollect,
    DemandsDetails: DemandsDetails
  },
  data() {
    const systemNavigation = [
      { url: "#ids0", name: "关联企业" },
      { url: "#ids1", name: "企业标签" },
      // { url: "#ids2", name: "政策精算报告" },
      // { url: "#ids3", name: "工业产品" },
      { url: "#ids2", name: "我的申报" },
      { url: "#ids3", name: "我的收藏" },
      { url: "#ids4", name: "我的活动" },
      { url: "#ids5", name: "我的诉求" }
      // { url: "#ids8", name: "我的需求" },
      // { url: "#ids9", name: "我的供应" },
      // { url: "#ids10", name: "修改密码" },
      // { url: "#ids11", name: "消息中心" },
    ];
    return {
      systemNavigation,
      isactive: 0,
      Qy: "",
      dialogTableVisible: false,
      value: 0,
      title: ["全部标签", "已选择的企业标签"],
      oldList: [],  
      allList: [],
      qyList: [],
      lastlist: "",
      EnterpriseDisabled: false,
      demandsList: {
        tableData: [],
        total: 0
      },
      pageSize: 10,
      demandsid: 0,
      demands_idshow: true,
      tagflag: true,
      indexval: 1
      // PageRouting: '',
    };
  },
  created() {
    if (localStorage.getItem("USERTYPE") == "ENTERPRISE") {
      this.systemNavigation.splice(0, 1);
    }
    if (localStorage.getItem("USERTYPE") == "USER") {
      this.tagflag = false;
    }
    this.initData();
    this.getDemandsList();
    this.$nextTick(() => {
      // 等待下一个 DOM 更新周期确保 el-dialog 可见后再初始化雷达图
      this.navigationSelect(
        "#ids" + this.$route.params.ids,
        this.$route.params.ids
      );
    });
  },
  methods: {
    openDialog() {
      this.dialogTableVisible = true;
    },
    demandsgo() {
      this.demands_idshow = true;
      this.indexval = this.indexval;
    },
    indexMethod(index) {
      return index + 1;
    },
    getDemandsList(pageindex) {
      let url = "/api/Demands/getDemandsList";
      let reqObj = {
        pagesize: this.pageSize,
        pageindex: pageindex
      };
      request.post(url, reqObj).then(res => {
        // console.log(res);
        if (res.data.Status == 1) {
          this.demandsList.tableData = [
            ...res.data.Data.data.map(item => {
              return {
                id: item.id,
                name: item.title,
                date: item.posttime.replace("T", " "),
                status: item.status
              };
            })
          ];
          this.demandsList.total = res.data.Data.total;
          // console.log(this.demandsList.total);
        }
      });
    },
    handleCurrentChange(val) {
      this.indexval = val;
      this.getDemandsList(val);
    },
    initData() {
      this.EnterpriseDisabled = true;
      request
        .get("/api/Login/GetLable")
        .then(response => {
          // console.log(response);
          this.oldList = [
            ...response.data.Data.qylabellist.map(item => item.id)
          ];
          this.allList = [
            ...response.data.Data.labellist.map(item => {
              return { key: item.id, label: item.name };
            })
          ];
          this.Qy = response.data.Data.qy;
          this.value = response.data.Data.qy;
          localStorage.setItem("COMPANYNAME", this.Qy);
          // console.log(this.oldList);
          // console.log(this.allList);
        })
        .catch(error => {
          // 请求失败时的处理
          // console.error("Error fetching data:", error);
        });
      request
        .get("/api/Login/getqy")
        .then(response => {
          this.qyList = [
            ...response.data.Data.map(item => {
              return { value: item.id, label: item.name };
            })
          ];
          // console.log(this.qyList);
        })
        .catch(error => {
          // 请求失败时的处理
          // console.error("Error fetching data:", error);
        });
    },
    navigationSelect(url, index) {
      // console.log(url);
      // console.log(index);
      this.isactive = index;
      document.querySelector(url).scrollIntoView(true);
      // if (url != this.PageRouting) {
      //     this.$router.push('/'+url.replace("#",""));
      // }
    },
    detailsrow(row) {
      // console.log(row.id);
      this.demandsid = row.id;
      this.demands_idshow = false;
      // console.log(this.demandsid);
      // this.$router.push("/DemandsDetails");
    },
    updatelable() {
      // console.log(this.oldList);
      for (let index = 0; index < this.oldList.length; index++) {
        this.lastlist += this.oldList[index];
        if (index + 1 < this.oldList.length) {
          this.lastlist += ",";
        }
      }

      request
        .post("/api/Login/UpdateLable", { newList: this.lastlist })
        .then(response => {
          // console.log(response);
        })
        .catch(error => {
          // 请求失败时的处理
          // console.error("Error fetching data:", error);
        });
    },
    selectChanged() {
      this.EnterpriseDisabled = false;
    },
    updateqy() {
      // console.log(this.value);
      request
        .post("/api/Login/Updateqy", { id: this.value })
        .then(response => {
          // console.log(response);
          localStorage.setItem("QYID", this.value);
          this.initData();
        })
        .catch(error => {
          // 请求失败时的处理
          // console.error("Error fetching data:", error);
        });
    }
    // getPath() {
    //     alert(this.$route.path)
    // }
  }
};
</script>
<style scoped>
@import url("../../../public/layui/css/layui.css");

* {
  margin: 0;
  padding: 0;
}

.qy {
  width: 600px;
  padding: 0 auto;
  height: 50px;
}

.xuanxiang {
  text-align: left;
  margin: 20px 0 0 120px;
}

.content {
  width: 100%;
  padding: 30px 0px;
  background-color: #e9e9e9;
}

ul li {
  width: 110px;
  background-color: #ffffff;
  padding: 18px 25px;
  font-size: 18px;
  text-align: left;
}

.active {
  background-color: #e6f4ff;
}

.active a {
  color: #0775dd;
}

ul li:hover {
  background-color: #e6f4ff;
}

ul li:hover a {
  color: #029bfc;
}

.content-item1 {
  width: 1000px;
  margin: 0px auto;
  display: flex;
}

.content-item {
  height: 660px;
  overflow: hidden;
  margin-top: 30px;
  margin-left: 30px;
}

.content-item div {
  width: 800px;
  height: 660px;
  padding-top: 10px;
  background-color: #ffffff;
}
.suspend {
  position: fixed;
  right: 1.125rem;
  bottom: 8.125rem;
  width: 5.3125rem;
  background: #ffffff;
  border-radius: 0.4375rem;
  padding: 1.25rem 0;
  z-index: 10;
}

.suspend .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suspend .item img {
  width: 1.75rem;
}

.suspend .item div {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.3125rem;
}
</style>